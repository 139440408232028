import React,{useState} from "react";

function Time() {
    const time = new Date().toLocaleTimeString();
    const [state,setTime] = useState(time);
    function updateTime() {
      const newTime = new Date().toLocaleTimeString();
      setTime(newTime);
      
    }
    setInterval(updateTime,1000)
    return(
      <div className="time-section">
        <h1 className="time-main">{time}</h1>
      </div>
    )
  
  }
  export default Time;
