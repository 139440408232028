import React from 'react';
import {Link} from "react-router-dom"
import Contact from './contact';
const DesktopHeader = () => {
  return (
          <section class="top-nav">
    <div>
      <p className='logo'>TehranSoceity</p>
    </div>
    <input id="menu-toggle" type="checkbox" />
    <label class='menu-button-container' for="menu-toggle">
    <div class='menu-button'></div>
  </label>
    <ul class="menu">
      <li><a className='button-header' href='https://qrcode.tehransoceity.com'>خانه</a></li>
      <li><Link className='button-header' to="/contact">تماس</Link></li>
      <li><a className='button-header'>آموزش</a></li>
      <li><a className='button-header'>تیکت</a></li>
    </ul>
  </section>


  );
};

export default DesktopHeader;
