import React from 'react';
import './App.css';
import { DownloadDemo } from './qr-code-demo.tsx';
import DesktopHeader from './DesktopHeader';
function App() {
  return (
      <div className="App">
          <DesktopHeader/>
        <header className="App-header">
          <DownloadDemo/>
        </header>
      </div>       
  );
}

export default App;
