import React from "react";
import DesktopHeader from "./DesktopHeader";
function Contact() {
    return (
        <div className="App">
            <DesktopHeader/>
            <header className="App-header">
                <div className="main-div-contact">
                    <div>
                        <section className="contact-section">
                            <h2 className="h2-contact">با ما در ارتباط باشید</h2>
                                <div className="input-sections-contact">
                                    <input className="input-contact" placeholder="نام" type="text"></input>
                                    <input className="input-contact" placeholder="ایمیل" type="email"></input>
                                    <input  className="input-contact" placeholder="پیشنهادات و انتقادات" type="text"></input>
                                </div>

                            <div className="main-div-checkbox">
                                <label >دریافت اعلانات و خبرنامه تهران سوسایتی</label>
                                <input type="checkbox" id="checkbox-contact"></input>
                            </div>


                        </section>
                    </div>
                </div>
            </header>
        </div>
    )
}
export default Contact;