import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import React, {ChangeEventHandler, useRef,useState} from 'react';
import Time from './Time.jsx';
import DesktopHeader from './DesktopHeader.js';
import TimeMobile from './TimeMobile.jsx';
function downloadStringAsFile(data: string, filename: string) {
  let a = document.createElement('a');
  a.download = filename;
  a.href = data;
  a.click();}


var qr_code = " Qr Code "
function DownloadDemo() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const [qrContent, setQrContent] = useState('');
  const [imageSrc, setImageSrc] = useState(
    ''
  );
  const date = new Date()

  const year = date.getFullYear()
  function onCanvasButtonClick() {
    const node = canvasRef.current;
    if (node == null) {
      return;
    }
    // For canvas, we just extract the image data and send that directly.
    const dataURI = node.toDataURL('image/png');

    downloadStringAsFile(dataURI, qrContent + '.png');
  }

  return (

  
    <>
      <div className="main-div">
      
      <h1 className='h1-text'>QR Code تولید</h1>
      <section className='h1-detail'>
          <p> شما با این سیستم میتوانید به راحتی</p>
          <p> تبدیل کنید{qr_code } لینک خود را به </p>
      </section>
        <input 
        type="text" 
        placeholder="لینک خود را وارد کنید"
        value={qrContent}
        onChange={(e) => setQrContent(e.target.value)}
      />
      <br />
        <div className='second-div'>
          <QRCodeCanvas className='qr-section' ref={canvasRef}
            value={qrContent} 
            size={300}/>

          <button className='button' onClick={onCanvasButtonClick} style={{display: 'block'}}>
             <span>{qr_code} دانلود </span>
             <div className="button__horizontal"></div>
             <div className="button__vertical"></div>
          </button>
          <TimeMobile/>
          <p className='copyright-mobile'>copyright TehranSoceity {year}</p>
          

        </div>
            <Time/>
            <p className='copyright'>copyright TehranSoceity {year}</p>
            
      </div>
      

      
    </>
  );
  
}

export {DownloadDemo};